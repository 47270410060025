import type { Thing, WithContext } from 'schema-dts'
import defu from 'defu'

type LdJsonScript = {
  type: 'application/ld+json'
  innerHTML: string
  tagPosition?: 'bodyClose' | 'bodyOpen' | 'head'
}

export const getLdJsonScript = <T extends Thing>(microData: T): LdJsonScript => {
  const schema: WithContext<T> = defu(microData, { '@context': 'https://schema.org' })

  return {
    type: 'application/ld+json',
    tagPosition: 'bodyClose',
    innerHTML: JSON.stringify(schema),
  }
}
