export const useLCP = ({ imagePath, ratio }: { imagePath?: string, ratio?: MaybeRef<number> }) => {
  const { public: { twicpics: { domain } } } = useRuntimeConfig()

  type WithOptionalHeight = { width: number, height?: number }
  const getTwicpicsCoverHref = ({ width, height }: WithOptionalHeight) => {
    if (!imagePath) {
      return undefined
    }
    const url = new URL(useTwicPicsBlogUrl(imagePath), domain)
    const h = height ?? (ratio ? Math.ceil(width / toValue(ratio)) : width)
    url.search = `twic=v1/focus=auto/cover=${width}x${h}` // prevent parameters to be encoded
    return url.href
  }

  const getSrcSetRow = ({ width, height }: WithOptionalHeight) => `${getTwicpicsCoverHref({ width, height })} ${width}w`

  const toSrcsetString = (s: Set<number>) => Array.from(s).sort((a, b) => a - b).map(width => getSrcSetRow({ width })).join(', ')

  // support DPR 3
  const addDPRWidthToSet = (s: Set<number>, width: number) => {
    s.add(width)
    s.add(width * 2)
    s.add(width * 3)
  }

  const getFixedWidthsSet = (width: number) => {
    const fixedWidths = new Set<number>()
    addDPRWidthToSet(fixedWidths, width)
    return fixedWidths
  }

  const getResponsiveWidthsSet = (maxWidth: number) => {
    const responsiveWidths = new Set<number>()
    const responsiveSteps: number[] = []
    let step = 300
    while (step < maxWidth) {
      responsiveSteps.push(step)
      step += 150 // arbitrary choice
    }
    responsiveSteps.push(maxWidth)
    responsiveSteps.forEach(width => addDPRWidthToSet(responsiveWidths, width))
    return responsiveWidths
  }

  return { getTwicpicsCoverHref, toSrcsetString, getFixedWidthsSet, getResponsiveWidthsSet }
}
